import axios from "axios";



let prefixURL = process.env.REACT_APP_URL
let TraceAdminPrefixURL = process.env.REACT_TRACEADMIN_URL 
let prefixURLTraceAdmin = process.env.REACT_APP_URL_LOGIN
const cancelTokenSource = axios.CancelToken.source();


export function getPproductivitylist(requestBody, sessionID, id,sub_org_id=0) {

    return axios.get(prefixURL + `/get_productivity_list?session=${sessionID}&org_id=${id}&start_date=${requestBody.startdate}&end_date=${requestBody.enddate}&slug=${requestBody.slug}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)

}
export function getworkingday( id,sub_org_id=0,sessionID) {
    return axios.get(prefixURLTraceAdmin + `/organization/get_work_day_list?org_id=${id}&sub_org_id=${sub_org_id}&session=${sessionID}`)
        .then(res => res.data).catch(err => err)
}
// /api/v1/get_worker_detailed_activity?session={{session}}&org_id=40&start_date=2025-03-24&end_date=2025-03-24&worker_id=traceplus1&sub_org_id=0
export function getWorkingDetailActivity( requestBody,id,sub_org_id=0,sessionID,worker_id) {
    return axios.get(prefixURL + `/get_worker_detailed_activity?org_id=${id}&sub_org_id=${sub_org_id}&session=${sessionID}&&start_date=${requestBody.startdate}&end_date=${requestBody.enddate}&worker_id=${worker_id}`)
        .then(res => res.data).catch(err => err)
}
export function getPproductivitylistDetail(requestBody, sessionID, id,sub_org_id=0) {
    return axios.get(prefixURL + `/get_productivity_list_detailed?session=${sessionID}&org_id=${id}&start_date=${requestBody.startdate}&end_date=${requestBody.enddate}&slug=${requestBody.slug}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}

export function getProductivityTemperature(requestBody, sessionID, id,sub_org_id=0,worker_id) {
    return axios.get(prefixURL + `/get_employee_temperature?session=${sessionID}&worker_id=${worker_id}&org_id=${id}&start_date=${requestBody.startdate}&end_date=${requestBody.enddate}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}
export function getProductivityMontion(requestBody, sessionID, id,sub_org_id=0,worker_id) {
    return axios.get(prefixURL + `/get_employee_activity_monitor?session=${sessionID}&worker_id=${worker_id}&org_id=${id}&start_date=${requestBody.startdate}&end_date=${requestBody.enddate}&slug=${requestBody.slug}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}

export function getDepartment(sessionID,id,sub_org_id=0){
    return axios.get(prefixURL+`/get_department_list?session=${sessionID}&org_id=${id}&sub_org_id=${sub_org_id}`).then(res=>res.data).catch(err=>err)
}
export function getSubDepartment(sessionID,department_id,org_id,sub_org_id=0){
    return axios.get(prefixURL+`/get_sub_department_list?department_id=${department_id}&session=${sessionID}&org_id=${org_id}&sub_org_id=${sub_org_id}&sub_org_id=${sub_org_id}`,).then(res=>res.data).catch(err=>err)
}
export function getShift(sessionID,org_id,department_id,sub_department_id,sub_org_id){
  
  
    if(sub_department_id!='' && sub_department_id!='all'){

        return axios.get(prefixURL+`/get_shift_list?org_id=${org_id}&session=${sessionID}&department_id=&sub_department_id=${sub_department_id}&sub_org_id=${sub_org_id}`).then(res=>res.data).catch(err=>err)
    }else{
        return axios.get(prefixURL+`/get_shift_list?org_id=${org_id}&session=${sessionID}&department_id=${department_id}&sub_department_id=&sub_org_id=${sub_org_id}`).then(res=>res.data).catch(err=>err)
    }
    
}

export function getPproductivityOverview(requestBody, sessionID, id,sub_org_id=0) {

         return axios.get(prefixURL + `/get_org_productivity_overview_filter?session=${sessionID}&org_id=${id}&start_date=${requestBody.startdate}&end_date=${requestBody.enddate}&slug=${requestBody.slug}&department_id=${requestBody.department_id}&sub_department_id=${requestBody.sub_department_id}&shift_id=${requestBody.shift_id}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)

}

export function getPproductivityOverviewAll(requestBody, sessionID, id,sub_org_id=0) {
    return axios.get(prefixURL + `/get_org_productivity_overview?session=${sessionID}&org_id=${id}&start_date=${requestBody.startdate}&end_date=${requestBody.enddate}&slug=${requestBody.slug}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
        
}


export function getPproductivityWidget(requestBody, sessionID, id,sub_org_id=0) {

    return axios.get(prefixURL + `/get_productivity_widget?session=${sessionID}&org_id=${id}&start_date=${requestBody.startdate}&end_date=${requestBody.enddate}&slug=${requestBody.slug}&worker_id=${requestBody.worker_id}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)

}
export function getPproductivityDetailOverview(requestBody, sessionID, id,sub_org_id=0) {
    return axios.get(prefixURL + `/get_productivity_detailed_overview?session=${sessionID}&org_id=${id}&start_date=${requestBody.startdate}&end_date=${requestBody.enddate}&slug=${requestBody.slug}&worker_id=${requestBody.worker_id}&sub_org_id=${sub_org_id}`,{
      cancelToken: cancelTokenSource.token
    }).then(res => res.data).catch(err => err)
}
export function getDetailedProductivityChart(requestBody, sessionID, id,sub_org_id=0) {

    return axios.get(prefixURL + `/get_detailed_productivity_chart?session=${sessionID}&org_id=${id}&start_date=${requestBody.startdate}&end_date=${requestBody.enddate}&slug=${requestBody.slug}&worker_id=${requestBody.worker_id}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)

}
export function getProductivityIndividualInfo(requestBody, sessionID, id,sub_org_id=0) {

    return axios.get(prefixURL + `/get_productivity_individual_info?session=${sessionID}&org_id=${id}&start_date=${requestBody.startdate}&end_date=${requestBody.enddate}&slug=${requestBody.slug}&worker_id=${requestBody.worker_id}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)

}

export function getIndividualProductivityList(requestBody,sessionID, id,sub_org_id=0){
    return axios.get(prefixURL + `/get_individual_productivity_list?session=${sessionID}&org_id=${id}&slug=${requestBody.slug}&worker_id=${requestBody.worker_id}&start_date=${requestBody.startdate}&end_date=${requestBody.enddate}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}

export function getPeformanceIndividualHistory(requestBody,sessionID, id,sub_org_id=0){
    return axios.get(prefixURL + `/get_individual_productivity_history?session=${sessionID}&org_id=${id}&slug=${requestBody.slug}&worker_id=${requestBody.worker_id}&start_date=${requestBody.startdate}&end_date=${requestBody.enddate}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}

export function getProductivityFunction(sessionID, id,sub_org_id=0){
        return axios.get(prefixURLTraceAdmin + `/employee/get_productivity_category?session=${sessionID}&org_id=${id}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}

export function getProductivityType(sessionID, id,sub_org_id=0){
        return axios.get(prefixURLTraceAdmin + `/location/get_location_type?session=${sessionID}&org_id=${id}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}
export function getLocationList(sessionID, id,sub_org_id=0){
        return axios.get(prefixURLTraceAdmin + `/location/get_locations?session=${sessionID}&org_id=${id}&sub_org_id=${sub_org_id}`)
        .then(res => res.data).catch(err => err)
}

export { cancelTokenSource };