import React,{useState,useEffect} from "react";
import { Row,Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getTranslatedText } from  "../../../common/utilities";
import {  Select} from 'antd';



export default function AutoCheckoutFilter (props){
    let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : moment().toDate();
    
    
    const [dates, setDate] = useState({
        start:date,
        end: date,
    });

    const [selectedLangValue, updateSelectedLangValue] = useState("en");
    const handleDate = (date, type) => {
        setDate((prev) => ({ ...prev, [`${type}`]: date })); 
    };
    const [exportEnable,SetExportEnable] = useState(false);
    const [selectedDate, updateSelectedDate] = useState(date);
    
    
    // const [filterSlide,SetFilterSlide]=useState(100);
    const [filterFOP,SetFilterFOP] =  useState(false);
    const [filter1,SetFilter1] = useState([]);
    const [filter2,SetFilter2] = useState([]);
    const [status,SetStatus] = useState(["Short-time","Over-time",'complied','Non Checkout']);
        

    let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
    let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
    let userSession = userDetails ? userDetails.session : "123456789";
    let org_id = userDetails ? userDetails.org_id : 6;


    
    let workday =props.workday;
    // const [volume,SetVolume]= useState('');    



    function getDateFormat(date) {
        return moment(date).format("YYYY-MM-DD");
      }

    function filterResultHandler(){
        SetFilterFOP(true);
       
        let requestBody={
          date:moment(dates.start).format('YYYY-MM-DD') ,
          start_date:moment(dates.start).format('YYYY-MM-DD') ,
          end_date:moment(dates.end).format('YYYY-MM-DD') ,
        }
        props.filterResultHandler(requestBody,true);
    }

    function exportToExcel(){
        props.exportEnable(true);
    }
    
    useEffect(()=>{
      return()=>{
        props.filterResultHandler({},false);
      }
    },[])

    
    
    return (
<Row className="">

                  <Col lg={4} className="p-r-0 report-filter">
                    <label>
                      {getTranslatedText("Start Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      <DatePicker
                        selected={dates.start}
                        value={dates.start}
                        onChange={(date) => {handleDate(date, "start");SetExportEnable(false);props.filterResultHandler({},false); }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("Date")}
                        maxDate={dates.end}
                        // minDate={new Date('2023-01-01')}
                        minDate={new Date('2023-01-01')}
                        
                      />
                   
                    </div>
                  </Col>
                                    <Col lg={4} className="p-r-0 report-filter">
                    <label>
                      {getTranslatedText("End Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px" }}
                    >
                      {console.log("selectedDate",selectedDate)}
                      <DatePicker
                        selected={dates.end}
                        value={dates.end}
                        onChange={(date) => {handleDate(date, "end");SetExportEnable(false);props.filterResultHandler({},false); }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("Date")}
                        maxDate={selectedDate}
                        // minDate={new Date('2023-01-01')}
                        minDate={dates.start}
                        
                      />
                   
                    </div>
                  </Col>
<Col lg={2} className="">
  <label>
    Action
  </label>
  <div className="btndiv">
                <button className="btn btn-submit btn-filter" onClick={()=>filterResultHandler()} disabled={dates.start==''|| dates.end==""}>Submit</button>
                <button className="btn btn-submit btn-filter btn-export" onClick={exportToExcel} disabled={!filterFOP} >Export</button>
                </div>
</Col>


                </Row>
                )

}