import React,{useState,useEffect, useRef} from "react";
import { Row,Col } from "react-bootstrap";

import moment from "moment";
import { getTranslatedText } from  "../../../common/utilities";

import { Tabs, Table, Collapse } from 'antd';
import Highcharts from 'highcharts';

import { AgGridReact,AgGridColumn } from 'ag-grid-react';

import spinnerLoader from "../../../assets/images/loader.svg";
import ExcelJS from 'exceljs';
import TraceLogo from '../../../assets/traceplusImages/trace_logo.png'
import { ToastContainer, toast } from "react-toastify";

import { getActivityReport, getAttendanceTime, getAutoCheckoutList, getPerformanceHistory } from "../../actionMethods/actionMethods";
import { getOnBoardEmp } from "../../../costManagement/actionMethods/actionMethods";
import { getWorkingDetailActivity } from "../../../productivity/actionMethods/actionMethods";
import dummData from './dummyData.json'
const { TabPane } = Tabs;
const { Panel } = Collapse;

export default function ActivityReport (props){
    let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();
    const [dates, setDate] = useState({
        start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
        end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    });
    
    const [selectedLangValue, updateSelectedLangValue] = useState("en");
    const handleDate = (date, type) => {
        setDate((prev) => ({ ...prev, [`${type}`]: date })); 
    };

    let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
    let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
    let userSession = userDetails ? userDetails.session : "123456789";
    let org_id = userDetails ? userDetails.org_id : 6;
    
      
    const [exportEnable,SetExportEnable] = useState(false);
    const [selectedDate, updateSelectedDate] = useState(date);
    const [plannedEmp,SetPlannedEmp]= useState([])
    const [employee,SetEmployee]= useState([]);
    const [filterData,SetFilterData] = useState([]);
    const [viewReport,setViewReport] = useState('all');
    const indonesiaTimeZone = userDetails?.organization_timezone?.organization_timezone_name || 'Asia/Jakarta';  
    let workday = props.workday;
    let start_hour= props.workday.start_hour?props.workday.start_hour.split(":"):'07:00:00'.split(":");
    let end_hour= props.workday.end_hour?props.workday.end_hour.split(":"):'06:00:00'.split(":");
    let addDay = props.workday && props.workday.start_hour==="00:00:00"?0:1  
      
  const [loader,SetLoader]= useState(true);
  const [processEmp,SetProcessEmp] = useState(0)


const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);

function capitalizeFirstLetterNew(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
 function getDateFormat(date) {
        return moment(date).format("YYYY-MM-DD");
      }

function iconRender(params){
        var op =document.createElement('div');

        var eGui = document.createElement('div');
        
        if(params.value  ==="yes" ){
          eGui.innerHTML=`<div style="text-align:center"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="#325c14" width="15px"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg></div>`;
        }else if (params.value  ==="no" ) {
          eGui.innerHTML=`<div style="text-align:center"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="#aa261f" width="15px"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg></div>`;
        }else{
          {
          eGui.innerHTML=`<div style="text-align:center"> - </div>`;
        }
        }
          
      
      return eGui;  
}
  const prevFilters = useRef(null);
  
  const processTime = (timeStr) => {
        const [hh, mm, ss] = timeStr.split(":").map(Number);
        return hh * 3600 + mm * 60 + (ss || 0); // Convert to total seconds
      };

 useEffect(() => {
    if (!props.filterActive) return;

    // Check if filters are the same as the previous ones
    const currentFilters = JSON.stringify(props.filterPassData);
    // if (prevFilters.current === currentFilters) return; // Prevent duplicate calls
    let requestBody = props.filterPassData;
    prevFilters.current = currentFilters; // Update the ref with new filters
    SetLoader(true);
    
    

    const fetchWorkerReport = async (workers, orgId, session, startDate, endDate) => {

      let performance ={};
      if(props.filterPassData.slug.includes('employee')){
       performance =  await getPerformanceHistory({...requestBody,slug:"employee"},userSession,org_id,sub_org_id).then(res=>res?.data);
      }
      if(props.filterPassData.slug.includes('daily_worker')){
          let performanceDaily =  await getPerformanceHistory({...requestBody,slug:"daily_worker"},userSession,org_id,sub_org_id).then(res=>res?.data);
          performance = {...performance,...performanceDaily}
      }


      let res= await getActivityReport(requestBody,userSession,org_id,sub_org_id);
      if(res.status===200){
        console.log("new Api Response",res,startDate,res.data[startDate],res.data['2025-03-31']);
      let parseData=  res.data[0][startDate]? res.data[0][startDate].map(el=>{
        return ({
          ...el,
          movement_minutes:processTime(el.movement_minutes),
          out_of_range_minutes:processTime(el.out_of_range_minutes),
          static_minutes:processTime(el.static_minutes),
          performance:performance?.[el.worker_id]?.[startDate] || 0
        })
      }):[]
      console.log("parseData debug",parseData)

      //sort by department sub department
         let deptSelected= [...props.filterPassData.department];
        let subdeptSelected= [...props.filterPassData.sub_department];

        let removeDept =props.filterPassData.employee.filter(item=> subdeptSelected.indexOf(item.sub_department) > -1 ).map(el=>el.department);
        for(let i=0;i<removeDept.length;i++ ){
          let index = deptSelected.indexOf(removeDept[i])
          if(index > -1 ){
            deptSelected.splice(index, 1); 
          }
        }
        console.log("removeDept",removeDept)
        console.log("deptSelected",deptSelected)
        console.log("subdeptSelected",subdeptSelected)


//old filter dept sub dept
      // let filterData = props.filterPassData.department.length > 0 && props.filterPassData.sub_department.length > 0?
      // parseData.filter(el=>props.filterPassData.department.includes(el.department) && props.filterPassData.sub_department.includes(el.sub_department)):
      // parseData.filter(el=>props.filterPassData.department.includes(el.department));
    let filterData = parseData.filter(item=>deptSelected.indexOf(item.department) > -1 || subdeptSelected.indexOf(item.sub_department) > -1 )


      console.log("sort after department sub department",filterData);
            //sort by slug;
        let keyAPI= []
        if(props.filterPassData.slug.includes('employee')){
          keyAPI.push('regular');
        }
        if(props.filterPassData.slug.includes('daily_worker')){
          keyAPI.push('daily_worker');
        }  
        filterData =  filterData.filter(el=> keyAPI.includes(el.worker_type) )
        console.log("sort after workerType",filterData);
      //sort by agency
      filterData = props.filterPassData.agency.length > 0? filterData.filter(el=>props.filterPassData.agency.includes(el.agency)):filterData;
      console.log("sort after Agency",filterData);


      
      if(props.filterPassData.filterBy==="movement"){
        filterData= filterData.filter(el=>el.movement_percentage >= props.filterPassData.threeshold) 
      }else if(props.filterPassData.filterBy==="static"){
        filterData= filterData.filter(el=>el.static_percentage >= props.filterPassData.threeshold) 
      }
      else if(props.filterPassData.filterBy==="out_of_range"){
        filterData= filterData.filter(el=>el.out_of_range_percentage >= props.filterPassData.threeshold) 
      }

      SetFilterData(filterData);
      SetLoader(false);
      }

    };
   
    fetchWorkerReport(
      props.filterPassData.employee,
      userDetails.org_id,
      userSession,
      props.filterPassData.startdate,
      props.filterPassData.enddate
    );



  }, [props.filterPassData, props.filterActive]);

useEffect(()=>{
  if(props.exportEnable){
    generateExcelFile(filterData);
  }
},[props.exportEnable,filterData])

 const generateExcelFile = async (data) => {
    const workbook = new ExcelJS.Workbook();
    const imageId = await getImageId(workbook, TraceLogo);
    
        await generateSheet(workbook, "Activity",data ,imageId);
    
  const fileName = 'ActivityReport '+moment(props.filterPassData.date).format('YYYY-MM-DD');
    const writeFile = (fileName, content) => {
              const link = document.createElement("a");
              const blob = new Blob([content], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;"
              });
              link.download = fileName;
              link.href = URL.createObjectURL(blob);
              link.click();
            };
          //const stream = fs.createWriteStream(filename);
          
          workbook.xlsx.writeBuffer().then((buffer) => {
              writeFile(fileName, buffer);
          });
      props.exportFalseFOP();
  };

  function valueRemoveNull(val){
    if(isNaN(val)){
      return 0
    }else if(val===null || val===undefined){
      return 0
    }else{
      return val
    }

  }

    const generateSheet = async (workbook, sheetName, data, logoID) => {
    const worksheet = workbook.addWorksheet(sheetName);

    // Add logo
    worksheet.addImage(logoID, {
        tl: { col: 0, row: 1 },
        ext: { width: 153, height: 34 },
    });

    // Add empty rows for spacing
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.mergeCells('A1:B4');
    worksheet.addRow([]);
worksheet.addRow([]);
    // Add header details
    worksheet.addRow(['Date', moment(props.filterPassData.date).format('YYYY-MM-DD')]).getCell(2).font = { bold: true };
    worksheet.addRow(['Activity', "Date"]).getCell(2).font = { bold: true };
    worksheet.addRow(['Filter By ', props.filterPassData.filterBy==="movement"?"Movement Time":props.filterPassData.filterBy ==="static"?"Idle Time":"Out Of Range Time"]).getCell(2).font = { bold: true };
    worksheet.addRow(['Threeshold ', props.filterPassData.threeshold+"%"]).getCell(2).font = { bold: true };
    worksheet.addRow(['Site', userDetails.org_name]).getCell(2).font = { bold: true };

    worksheet.addRow([]);

    // Generate date range
    const dateRange = [];
    let currentDate = new Date(props.filterPassData.start_date);
    let end = new Date(props.filterPassData.end_date);

    while (currentDate <= end) {
        dateRange.push(currentDate.toISOString().split('T')[0]); // 'YYYY-MM-DD'
        currentDate.setDate(currentDate.getDate() + 1);
    }

    // Prepare table data
    console.log("in excel data",data);
    const tableData = data.map(item => [
        item.name,
        item.worker_id,
        item.tag_serial,
        item.agency,
        item.department,
        item.sub_department,
        parseFloat(item.performance),
        secondsToHHMMSS(item.movement_minutes),
        Number(item.movement_percentage.toFixed(2)),
        secondsToHHMMSS(item.static_minutes),
        Number(item.static_percentage.toFixed(2)),
        secondsToHHMMSS(item.out_of_range_minutes),
        Number(item.out_of_range_percentage.toFixed(2)),
        item.steps

    ]);
    console.log("tableData",tableData,[
            { name: 'Name' }, 
            { name: 'Worker ID' }, 
            { name: 'Tag Serial' }, 
            { name: 'Agency' }, 
            { name: 'Function' }, 
            { name: 'Sub Function' }, 
            { name: 'Performance' }, 
            { name: 'Movement Time' }, 
            { name: 'Movement Percent' }, 
            { name: 'Idle Time' }, 
            { name: 'Idle Percent' }, 
            { name: 'Out of Range Time' }, 
            { name: 'Out of Range Percent' }, 
            { name: 'Total Steps' }
            
        ]);

    // Create table
    worksheet.addTable({
        name: sheetName.replaceAll(' ', '_'),
        ref: `A13`,
        columns: [
            { name: 'Name' }, 
            { name: 'Worker ID' }, 
            { name: 'Tag Serial' }, 
            { name: 'Agency' },
            { name: 'Function' }, 
            { name: 'Sub Function' }, 
            { name: 'Performance' }, 
            { name: 'Movement Time' }, 
            { name: 'Movement Percent' }, 
            { name: 'Idle Time' }, 
            { name: 'Idle Percent' }, 
            { name: 'Out of Range Time' }, 
            { name: 'Out of Range Percent' }, 
            { name: 'Total Steps' }
            
        ],
        rows: tableData,
    });

    const table = worksheet.getTable(sheetName.replaceAll(' ', '_'));

    // Auto-adjust column widths
    worksheet.columns.forEach(column => {
        const lengths = column.values.map(v => v ? v.toString().length : 1);
        const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
        column.width = maxLength + 2;
    });

  

    // Commit table changes
    table.commit();
};

  

  const getImageId = async (workbook, imageUrl) => {
    const response = await fetch(imageUrl);
    const arrayBuffer = await response.arrayBuffer();
    const base64Image = Buffer.from(arrayBuffer).toString('base64');

    return workbook.addImage({
      base64: base64Image,
      extension: 'png', // Change the extension based on your image format
    });
  };

  function capitalizeFirstLetter(string) {
      if(string){
        return string.toLowerCase();
      }
  }

  function AGGridEMptyMEssage(key){
    let msg;
        msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
    return msg
  }


const compressedTableStyles = {
  margin: 0,
  padding: 0,
};
const compressedRowStyles = {
  lineHeight: '1.2rem', // Adjust the line height as needed
};


 const getImageSize = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
      toast.error("Fail to export please again after sometime");
    }
  const blob = await response.blob();
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.src = URL.createObjectURL(blob);
  });
};


function timeStringToMilliseconds(timeString) {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  return ((hours * 60 + minutes) * 60 + seconds) * 1000;
}

function millisecondsToTimeString(milliseconds) {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}

function valueRenderTime(params){
        var op =document.createElement('div');

        var eGui = document.createElement('div');
        if(params.value ==="" || params.value ==="00:00:00"){
          eGui.innerHTML=`<div class="sucessColor"> - </div>`;
        }
        else if(params.data['Work_time_status'] === 'Over-time' || params.data['Work_time_status'] === 'complied' ){
          eGui.innerHTML=`<div class="sucessColor">${params.value}</div>`;
        }else{
          eGui.innerHTML=`<div class="redColor">${params.value}</div>`;
        }
          
      
      return eGui;  
}



function valueRenderTimeExcel(params){

        const actualWorkHoursMs = timeStringToMilliseconds(params.actual_work_hours);
const planWorkHoursMs = timeStringToMilliseconds(params.plan_work_hours);

const differenceMs = actualWorkHoursMs - planWorkHoursMs;
const differenceTimeString = millisecondsToTimeString(Math.abs(differenceMs));
const differenceSign = differenceMs >= 0 ? '' : '-';

        


      return `${differenceSign}${differenceTimeString}`;  
}
 

const secondsToHHMMSS = (totalSeconds) => {
   const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    let result = [];

    if (hours > 0) result.push(`${hours}H`);
    result.push(`${minutes}M`);
    result.push(`${seconds}S`);

    return result.join(" ");
};

  function AGTablePlanActual(data,type){    
      console.log("data display",data);

        let arr;
        let date='';

        arr=<div className={"keyAGTableSmallPlan keyAGTableSmallPlanHeader"}><div className="ag-theme-alpine if cell-size-40" style={{height:(filterData.length*42.5)+100, width: "calc(100% - 1px)"}}>

          <AgGridReact
              rowHeight={30}
            
              headerHeight={60}
              onCellClicked={(params)=>{
                if(params.data.worker_type==="regular"){
                  console.log("props.filterPassData",props.filterPassData)
                  window.open(`/productivity/employee/:${params.data.worker_id}/${props.filterPassData.start_date}`)
                }else{
                  window.open(`/productivity/daily_worker/:${params.data.worker_id}/${props.filterPassData.start_date}`)
                }
              }
            }
                
                
    
           defaultColDef={{sortable: true,resizable: true,minWidth:45,flex: 1, suppressColumnVirtualisation:true,skipHeaderOnAutoSize:true}}           
              //pagination={true}
              rowData={data}
              key={'keyAGTable'}
              
              overlayNoRowsTemplate={
                  AGGridEMptyMEssage('')
                }        
           >

            <AgGridColumn
              field="name"
              headerName={"Name"}
              valueFormatter={(params)=>params.value?params.value.toLowerCase():"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
          />
          <AgGridColumn
              field="worker_id"
              headerName={"Worker ID"}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap textLeft'}
              flex={1}
          />
          
          <AgGridColumn
              field="tag_serial"
              headerName={"Tag Serial"}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap textLeft'}
              flex={1}
          />
          
          <AgGridColumn
              field="agency"
              headerName={"Agency"}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap textLeft'}
              flex={1}
          />
                    <AgGridColumn
              field="department"
              headerName={"Function"}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap textLeft'}
              flex={1}
          />
          <AgGridColumn
              field="sub_department"
              headerName={"Sub Function"}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap textLeft'}
              flex={1}
          />
                    <AgGridColumn
              field="performance"
              headerName={"Performance"}
              valueFormatter={(params)=>params.value!=" - "?params.value+"%":"-"}
              cellClass={'textCapitalize textCap textLeft'}
              flex={1}
          />
          {(type==="all" || type==="time" ) &&
          <AgGridColumn
              field="movement_minutes"
              headerName={"Movement Time"}
              valueFormatter={(params)=>params.value?secondsToHHMMSS(params.value):"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
          />}
          {(type==="all" || type==="percent" ) &&
          <AgGridColumn
              field="movement_percentage"
              headerName={"Movement Percent"}
              valueFormatter={(params)=>params.value?params.value.toFixed(2)+"%":"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
          />}
          {(type==="all" || type==="time" ) &&
          <AgGridColumn
              field="static_minutes"
              headerName={"Idle Time"}
              valueFormatter={(params)=>params.value?secondsToHHMMSS(params.value):"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
          />}
          {(type==="all" || type==="percent" ) &&
          <AgGridColumn
              field="static_percentage"
              headerName={"Idle Percent"}
              valueFormatter={(params)=>params.value?params.value.toFixed(2)+"%":"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
          />}
          {(type==="all" || type==="time" ) &&
                    <AgGridColumn
              field="out_of_range_minutes"
              headerName={"Out of Range Time"}
              valueFormatter={(params)=>params.value?secondsToHHMMSS(params.value):"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
          /> }
          {(type==="all" || type==="percent" ) && 
          <AgGridColumn
              field="out_of_range_percentage"
              headerName={"Out of Range Percent"}
              valueFormatter={(params)=>params.value?params.value.toFixed(2)+"%":"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
          /> 
          }
          <AgGridColumn
              field="steps"
              headerName={"Total Steps"}
              valueFormatter={(params)=>params.value?params.value:"-"}
              cellClass={'textCapitalize textCap'}
              flex={1}
          />

 
      </AgGridReact>
            
            </div></div>
      
      return arr
    }


     return (props.filterActive ?
      loader?                                <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                  
                                </div>:
    <div className="App">
    <ToastContainer/>
    <Row>
      <Col lg="12"><h5 style={{marginBottom:15}}>Attendance Report:  <span style={{color:"#ef5e8c"}}>Activity</span></h5>

          <div class="btnINline" style={{float:"right",position:"absolute",right:"20px",top:0}}>
            <button className={`btn btn-pink btn-primary ${viewReport==="all"?'active':""}`} onClick={()=>setViewReport('all')}>All</button>
            <button className={`btn btn-pink btn-primary ${viewReport==="time"?"active":""}`} onClick={()=>setViewReport('time')}>Time</button>
            <button className={`btn btn-pink btn-primary ${viewReport==="percent"?"active":""}`} onClick={()=>setViewReport('percent')}>Percent</button>
          </div>
      </Col>
      
    </Row>
      {AGTablePlanActual(filterData,viewReport)}

    </div>:""
    )

}