import React,{useState,useEffect, useRef} from "react";
import { Row,Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getTranslatedText } from  "../../../common/utilities";
import {  Select, Slider} from 'antd';

export default function ActivityFilter (props){
    let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : moment().toDate();
   
    
    const [dates, setDate] = useState({
        start:date,
        end: date,
    });
 useEffect(()=>{
  setDate({start:props.date,end:props.date})
  SetAgency([]);
  SetSelectedDepartemnt([]);
  SetSubDeparment([]);
  SetSelectedSubDepartemnt([])
  SetInitial(false);
 },[props.date])
    const [selectedDepartment,SetSelectedDepartemnt] = useState([]);
    const [selectedSubDepartment,SetSelectedSubDepartemnt] = useState([]);
    const [slug,setSlug] = useState(['employee','daily_worker'])
    const [agency,SetAgency]= useState([]);
  
    console.log("props.department",props.department)
    const [selectedEmployee,SetSelectedEmployee] = useState(...props.employee.map(el=>el.worker_id));
const [department,SetDeparment]= useState([]);
const [sub_department,SetSubDeparment]= useState(props.department.reduce((prev,current)=>[...prev,...current.sub_department],[]));

const [reportView,SetReportView] = useState('hour')
const [filterBy,SetFilterBy] = useState('');
const [threeshold,SetThreeshold]=  useState(0);

const [employee,SetEmployee]=useState([]);
const [initial,SetInitial]= useState(false)
    const [selectedLangValue, updateSelectedLangValue] = useState("en");
    const handleDate = (date, type) => {
        setDate((prev) => ({ ...prev, [`${type}`]: date })); 
    };
    const [exportEnable,SetExportEnable] = useState(false);
    const [selectedDate, updateSelectedDate] = useState(date);
    
    
    // const [filterSlide,SetFilterSlide]=useState(100);
    const [filter1,SetFilter1] = useState([]);
    const [filter2,SetFilter2] = useState([]);
    const [status,SetStatus] = useState(["Short-time","Over-time",'complied','Non Checkout']);
      const [filterFOP,SetFilterFOP]  = useState(false)

    let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
    let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
    let userSession = userDetails ? userDetails.session : "123456789";
    let org_id = userDetails ? userDetails.org_id : 6;


    
    // const [volume,SetVolume]= useState('');    
    let workday =props.workday;
    const  deprartmentRef= useRef(null) 
    console.log("props debug",props)

    function getDateFormat(date) {
        return moment(date).format("YYYY-MM-DD");
      }

    function filterResultHandler(){
        
        console.log("props.employee filter",props.employee,props.employee.filter(el=>selectedDepartment.indexOf(el.department) > -1 && selectedSubDepartment.indexOf(el.sub_department) > -1))
        let requestBody={
          date:moment(dates.start).format('YYYY-MM-DD') ,
          start_date:moment(dates.start).format('YYYY-MM-DD') ,
          startdate:moment(dates.start).format('YYYY-MM-DD') ,
          end_date:moment(dates.start).format('YYYY-MM-DD'),
          enddate:moment(dates.start).format('YYYY-MM-DD'),
          employee: props.employee,
          selectedEmployee:selectedEmployee,
          department:selectedDepartment,
          sub_department:selectedSubDepartment,
          filterBy:filterBy,
          threeshold:threeshold || 0,
          agency:agency,
          slug:slug
        }
        // console.log("requestBody requestBody",requestBody,requestBody.employee);
        props.filterResultHandler(requestBody,true);
        setTimeout(function(){SetFilterFOP(true);},1000)
    }

    function exportToExcel(){
        props.exportEnable(true);
    }
    
    useEffect(()=>{
      return()=>{
        props.filterResultHandler({},false);
      }
    },[])

    function onChangeDepartment(val){

  let dept =props.department;
      console.log("props.employee",props.employee)

  // let dept =props.department
  let value = props.department.map(el=>el.name);
  SetSelectedSubDepartemnt([])
  if(val.indexOf('all')> -1){

      if((val.length -1) == value.length ){
                console.log("condition 1 change");
        SetSelectedDepartemnt([]);  
        SetSelectedEmployee([]);
        SetSubDeparment([]);

      }else{
                console.log("condition 2 change");
        SetSubDeparment(props.department.reduce((prev,current)=>[...prev,...current.sub_department],[]))
        SetSelectedDepartemnt(props.department.map(el=>el.name));
      }
  }else{
                console.log("condition 3 change",val,props.employee.filter(el=>val.indexOf(el.department) > -1));  
    SetSelectedDepartemnt(val);
    // props.department.reduce((prev,current)=>[...prev,...current.sub_department],[])
    SetSubDeparment(props.department.filter(el=>val.indexOf(el.name) >-1).reduce((prev,current)=>[...prev,...current.sub_department],[]))

  }
  props.filterResultHandler({},false)
  SetExportEnable(false);
  // SetExportEnable(false);
}
    function onChangeSubDepartment(val){

  let value =props.department.filter(el=>selectedDepartment.indexOf(el.name) > -1 ).reduce((prev,current)=>[...prev,...current.sub_department],[])
      console.log("onchangesub dept",value);

  // let dept =props.department
  if(val.indexOf('all')> -1){
  
      if((val.length -1) == value.length ){
        console.log("condition 1");
        SetSelectedSubDepartemnt([]);  

      }else{
         console.log("condition 2");
        SetSelectedSubDepartemnt(value); 
        
      }
      
  }else{
     console.log("condition 3");
    SetSelectedSubDepartemnt(val);
  }
  props.filterResultHandler({},false)
  SetExportEnable(false);
  // SetExportEnable(false);
}   
function OnChangeAgency(val){
  if(val.indexOf('all')> -1){
      let value =[];
      let agencyList = [];
      if(slug.includes('employee')){
          agencyList.push(...props.agencyRW)
      }
      if(slug.includes('daily_worker')){
          agencyList.push(...props.agencyDW)
      }
        value = agencyList;
      if((val.length -1) == value.length ){
        SetAgency([]);  
      }else{
        SetAgency(value);
      }

      
  }else{
    SetAgency(val);
  }
  props.filterResultHandler({},false)
  SetExportEnable(false);
}

    return (
<Row className="">

                  <Col lg={2} className="p-r-0 report-filter">
                    <label>
                      {getTranslatedText("Date")}
                      </label>
                    <div
                      className="startDateEndDateMainDiv"
                      style={{ paddingLeft: "0px",padding:0 }}
                    >
                      <DatePicker
                        selected={dates.start}
                        value={dates.start}
                        onChange={(date) => {
                          handleDate(date, "start");
                          props.setDate({start:date,end:date})
                          SetExportEnable(false);props.filterResultHandler({},false);props.setDataLoad();SetAgency([]);SetSelectedDepartemnt([]);SetSelectedSubDepartemnt([]) }}
                        dateFormat={"MMM dd"}
                        isClearable={false}
                        placeholderText={getTranslatedText("Date")}
                        maxDate={props.date}
                        // minDate={new Date('2023-01-01')}
                        minDate={new Date('2023-01-01')}
                        
                      />
                   
                    </div>
                  </Col>
                  <Col lg={2} className="p-r-0 report-filter report-filter2">
                      <label>
                        {getTranslatedText("Employee Type")}
                      </label>
                        <Select
                          onChange={(e)=>{setSlug(e);props.filterResultHandler({},false);SetSelectedDepartemnt([]);SetSelectedSubDepartemnt([])}}
                          style={{width:"100%"}}
                          mode="multiple"
                          value={slug}
                          dropdownClassName="smallFontStyle"
                          dropdownStyle={{fontSize:10}} 
                        >
                          <Select.Option value="employee">Regular Worker</Select.Option>
                          <Select.Option value="daily_worker">Daily Worker</Select.Option>
                        </Select>
                  </Col>
                  <Col lg={2} className="p-r-0 report-filter report-filter2">
                      <label>
                        {getTranslatedText("Agency")}
                      </label>
                        <Select
                          onChange={(e)=>{OnChangeAgency(e);SetSelectedDepartemnt([]);SetSelectedSubDepartemnt([])}}
                          style={{width:"100%"}}
                          mode="multiple"
                          value={agency}
                          dropdownClassName="smallFontStyle"
                          dropdownStyle={{fontSize:10}}
                          disabled={slug.length===0}
                        >
                          <Select.Option value={'all'} key={"agncyAll"}>All</Select.Option>
                          {slug.includes('employee') && props.agencyRW.map(el=> <Select.Option value={el} key={"agncyRew"+el}>{el}</Select.Option>)}
                          {slug.includes('daily_worker') && props.agencyDW.map(el=> <Select.Option value={el} key={"agncyDW"+el}>{el}</Select.Option>)}
                        </Select>
                  </Col>

                                    <Col lg={6} className="p-r-0 report-filter report-filter2">
                                    <div style={{width:"50%",paddingRight:10,float:"left"}}>
                                                            <label>
                        {getTranslatedText("Department")}
                      </label>
                        <Select
                          onChange={(e)=>onChangeDepartment(e)}
                          style={{width:"100%"}}
                          mode="multiple"
                          value={selectedDepartment}
                          dropdownClassName="smallFontStyle"
                          dropdownStyle={{fontSize:10}}
                          disabled={slug.length==0 || agency.length==0 }
                        >
                          <Select.Option value="all">Select All</Select.Option>
                          {/* {props.department?props.department.map((el,index)=> <Select.Option key={"dept"+index+el.name} value={el.name}>{el.name}</Select.Option>):""} */}
                          {slug.includes('employee') && props.department.map((el,index)=> <Select.Option key={"dept"+index+el.name} value={el.name}>{el.name}</Select.Option>)}
                          {slug.includes('daily_worker') && props.departmentDaily.map((el,index)=> <Select.Option key={"deptDaily"+index+el.name} value={el.name}>{el.name}</Select.Option>)}               
                        </Select>
                                    </div>
                                    <div style={{width:"50%",paddingRight:10,float:"left"}}>
                      <label>
                        {getTranslatedText("Sub Department")}
                      </label>
                        <Select
                          onChange={(e)=>onChangeSubDepartment(e)}
                          style={{width:"100%"}}
                          mode="multiple"
                          value={selectedSubDepartment}
                          dropdownClassName="smallFontStyle"
                          dropdownStyle={{fontSize:10}}
                          disabled={slug.length==0 || agency.length==0 || selectedDepartment.length ===0}
                          
                        >
                          <Select.Option value="all">Select All</Select.Option>
                          {/* {props.department?props.department.map((el,index)=> <Select.Option key={"dept"+index+el.name} value={el.name}>{el.name}</Select.Option>):""} */}
                          {sub_department?sub_department.map((el,index)=> <Select.Option key={"subemp"+el} value={el}>{el}</Select.Option>):""}
                        </Select>
                                    </div>

                  </Col>
                    <Col lg={2} className="p-r-0 report-filter report-filter2" style={{marginTop:8}}>
                                                <label>
                        {getTranslatedText("FIlter By")}
                      </label>
                        <Select
                          onChange={(e)=>{SetFilterBy(e); props.filterResultHandler({},false);SetExportEnable(false);}}
                          style={{width:"100%"}}
                          value={filterBy}
                          dropdownClassName="smallFontStyle"
                          dropdownStyle={{fontSize:10}}
                        >
                          <Select.Option value="movement">Movement Time</Select.Option>
                          <Select.Option value="static">Idle Time </Select.Option>
                          <Select.Option value="out_of_range">Out Of Range Time </Select.Option>
                        </Select>
                  </Col>
                              <Col lg={8} style={{paddingRight:0,marginTop:10}}>
                      
  <div className="mgSpace mgSpacenewTooltip">
 <label>
    Threeshold <strong style={{color:"#ef5e8c"}}>({threeshold}%)</strong>
  </label>
  <Slider
    max={100}
    min={0}
    value={threeshold}    
    onChange={(val)=>{SetThreeshold(val);props.filterResultHandler({},false);SetExportEnable(false);}}
    // classNames={["myTooltip"]}  
    tooltipVisible={false}
  />
  
  </div>
 </Col> 

<Col lg={2} className="" style={{marginTop:8}}>
  <label>
    Action
  </label>
  <div className="btndiv">
                <button className="btn btn-submit btn-filter" onClick={()=>filterResultHandler()} disabled={dates.start==''|| selectedDepartment.length === 0 || filterBy=="" }>Submit</button>
                <button className="btn btn-submit btn-filter btn-export" onClick={exportToExcel} disabled={!filterFOP} >Export</button>
                {/* <button className="btn btn-submit btn-filter btn-export" onClick={exportToExcel} disabled >Export</button> */}
                </div>
</Col>


                </Row>
                )

}