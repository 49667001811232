import React,{useState,useEffect} from "react";
import { Row,Col } from "react-bootstrap";

import moment from "moment";
import { getTranslatedText } from  "../../../common/utilities";

import { Tabs, Table, Collapse } from 'antd';
import Highcharts from 'highcharts';

import { AgGridReact,AgGridColumn } from 'ag-grid-react';

import spinnerLoader from "../../../assets/images/loader.svg";
import ExcelJS from 'exceljs';
import TraceLogo from '../../../assets/traceplusImages/trace_logo.png'
import { ToastContainer, toast } from "react-toastify";

import { getAttendanceTime, getAutoCheckoutList } from "../../actionMethods/actionMethods";
import { getOnBoardEmp } from "../../../costManagement/actionMethods/actionMethods";

const { TabPane } = Tabs;
const { Panel } = Collapse;

export default function AutoCheckout (props){
    let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();
    const [dates, setDate] = useState({
        start: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
        end: new Date(moment(date).format('YYYY-MM-DD 23:59:59')),
    });
    
    const [selectedLangValue, updateSelectedLangValue] = useState("en");
    const handleDate = (date, type) => {
        setDate((prev) => ({ ...prev, [`${type}`]: date })); 
    };

    let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));
    let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
    let userSession = userDetails ? userDetails.session : "123456789";
    let org_id = userDetails ? userDetails.org_id : 6;
    
      
    const [exportEnable,SetExportEnable] = useState(false);
    const [selectedDate, updateSelectedDate] = useState(date);
    const [plannedEmp,SetPlannedEmp]= useState([])
    const [employee,SetEmployee]= useState([]);
    const [filterData,SetFilterData] = useState([]);
    const indonesiaTimeZone = userDetails?.organization_timezone?.organization_timezone_name || 'Asia/Jakarta';  
    let workday = props.workday;
    let start_hour= props.workday.start_hour?props.workday.start_hour.split(":"):'07:00:00'.split(":");
    let end_hour= props.workday.end_hour?props.workday.end_hour.split(":"):'06:00:00'.split(":");
    let addDay = props.workday && props.workday.start_hour==="00:00:00"?0:1  
      
  const [loader,SetLoader]= useState(true);


const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);

function capitalizeFirstLetterNew(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
 function getDateFormat(date) {
        return moment(date).format("YYYY-MM-DD");
      }

//working
// function generateWorkerAttendance(allWorkerList, autoCheckOutList, startDate, endDate, startHour, endHour, addDay = 0) {
//     const workerMap = new Map();

//     // Step 1: Extract Unique Workers
//     allWorkerList.forEach(worker => {
//         if (!workerMap.has(worker.worker_id)) {
//             workerMap.set(worker.worker_id, {
//                 name: worker.name || "unknown",
//                 worker_id: worker.worker_id,
//                 tag_serial: worker.tag_id,
//                 attendance: {},
//                 total_count:0
                
//             });
//         }
//     });

//     // Step 2: Create Date Range
//     const dateRange = [];
//     let currentDate = new Date(startDate);
//     let end = new Date(endDate);

//     while (currentDate <= end) {
//         dateRange.push(currentDate.toISOString().split('T')[0]); // 'YYYY-MM-DD'
//         currentDate.setDate(currentDate.getDate() + 1);
//     }

//     // Step 3: Function to check if a worker was present in the shift time range
//     function isWithinShiftTime(actualCheckIn, refDate) {
//         const checkInDate = new Date(actualCheckIn);
//         const shiftStart = new Date(refDate);
//         shiftStart.setHours(startHour[0], startHour[1], startHour[2], 0);

//         const shiftEnd = new Date(refDate);
//         shiftEnd.setDate(shiftEnd.getDate() + addDay);
//         shiftEnd.setHours(endHour[0], endHour[1], endHour[2], 0);

//         return checkInDate >= shiftStart && checkInDate <= shiftEnd;
//     }

//     // Step 4: Mark attendance based on actual_check_in
//     autoCheckOutList.forEach(worker => {
//         const actualCheckInDate = new Date(worker.actual_check_in.replaceAll(" GMT","")).toISOString().split('T')[0];

//         if (workerMap.has(worker.worker_id)) {
//             dateRange.forEach(date => {
//                 if (isWithinShiftTime(worker.actual_check_in.replaceAll(" GMT",""), date)) {
//                     workerMap.get(worker.worker_id).attendance[date] = "yes";
//                     workerMap.get(worker.worker_id).total_count= workerMap.get(worker.worker_id).total_count+1;
                    
//                 }
//             });
//         }
//     });

//     // Step 5: Format Output
//     return Array.from(workerMap.values()).map(worker => {
//         const record = {
//             name: worker.name,
//             worker_id: worker.worker_id,
//             tag_serial: worker.tag_serial,
//             total_count:worker.total_count
//         };

//         dateRange.forEach(date => {
//             record[date] = worker.attendance[date] || "no";
//         });

//         return record;
//     });
// }
function generateWorkerAttendance(allWorkerList, autoCheckOutList, startDate, endDate, startHour, endHour, addDay = 0) {
    const workerMap = new Map();

    // Step 1: Extract Unique Workers
    allWorkerList.forEach(worker => {
        if (!workerMap.has(worker.worker_id)) {
            workerMap.set(worker.worker_id, {
                name: worker.name || "unknown",
                worker_id: worker.worker_id,
                tag_serial: worker.tag_id,
                attendance: {},
                total_count: 0
            });
        }
    });

    // Step 2: Create Date Range
    const dateRange = [];
    let currentDate = new Date(startDate);
    let end = new Date(endDate);

    while (currentDate <= end) {
        dateRange.push(currentDate.toISOString().split('T')[0]); // 'YYYY-MM-DD'
        currentDate.setDate(currentDate.getDate() + 1);
    }

    // Step 3: Function to check if a worker was present in the shift time range
    function isWithinShiftTime(actualCheckIn, refDate) {
        if (!actualCheckIn) return false;
        const checkInDate = new Date(actualCheckIn.replace(" GMT", ""));
        const shiftStart = new Date(refDate);
        shiftStart.setHours(startHour[0], startHour[1], startHour[2], 0);

        const shiftEnd = new Date(refDate);
        shiftEnd.setDate(shiftEnd.getDate() + addDay);
        shiftEnd.setHours(endHour[0], endHour[1], endHour[2], 0);

        return checkInDate >= shiftStart && checkInDate <= shiftEnd;
    }

    // Step 4: Mark attendance based on actual_check_in
    autoCheckOutList.forEach(worker => {
        if (!worker.actual_check_in) return;
        const actualCheckInDate = new Date(worker.actual_check_in.replace(" GMT", "")).toISOString().split('T')[0];

        if (workerMap.has(worker.worker_id)) {
            dateRange.forEach(date => {
                let checkWorker = allWorkerList.find(el => 
                    el.worker_id === worker.worker_id &&
                    new Date(el.check_in).toISOString().split('T')[0] === date
                );

                if (checkWorker) {
                    if (isWithinShiftTime(worker.actual_check_in.replace(" GMT", ""), date)) {
                        // ✅ Prevent overriding "yes" if already marked
                        if (workerMap.get(worker.worker_id).attendance[date] !== "yes") {
                            workerMap.get(worker.worker_id).attendance[date] = "yes";
                            workerMap.get(worker.worker_id).total_count++;
                        }
                    } else {
                        // ✅ Only mark "no" if "yes" hasn't been assigned
                        if (!workerMap.get(worker.worker_id).attendance[date]) {
                            workerMap.get(worker.worker_id).attendance[date] = "no";
                        }
                    }
                }
            });
        }
    });

    // Step 5: Mark "absent" for missing workers
    workerMap.forEach(worker => {
        dateRange.forEach(date => {
            if (!worker.attendance[date]) {
                worker.attendance[date] = "absent";
            }
        });
    });

    // Step 6: Format Output
    return Array.from(workerMap.values()).map(worker => {
        const record = {
            name: worker.name,
            worker_id: worker.worker_id,
            tag_serial: worker.tag_serial,
            total_count: worker.total_count
        };

        dateRange.forEach(date => {
            record[date] = worker.attendance[date];
        });

        return record;
    });
}


function iconRender(params){
        var op =document.createElement('div');

        var eGui = document.createElement('div');
        
        if(params.value  ==="yes" ){
          eGui.innerHTML=`<div style="text-align:center"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="#325c14" width="15px"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg></div>`;
        }else if (params.value  ==="no" ) {
          eGui.innerHTML=`<div style="text-align:center"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="#aa261f" width="15px"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg></div>`;
        }else{
          {
          eGui.innerHTML=`<div style="text-align:center"> - </div>`;
        }
        }
          
      
      return eGui;  
}

useEffect(()=>{
  let reportHead=[]
async function filterResultHandler(){
    try{
        if(props.filterActive){
          let requestBody = props.filterPassData;
        
            
        
        let startDate = new Date(requestBody.start_date);
        let endDate = new Date(requestBody.end_date);
          SetLoader(true);
          let mapList =  await getOnBoardEmp(userSession,org_id,requestBody.start_date,requestBody.end_date,sub_org_id).then(res=> {
            console.log(res);
            let all=[]
            if(res.data.daily){
              all.push(...res.data.daily);
            }
            if(res.data.regular){
              all.push(...res.data.regular);
            }
            return all;
          });

          let uniqueMapList = [...new Set(mapList.map(el=>el.worker_id))]
          console.log("uniqueMapList",uniqueMapList);
          
          let empUnFormated  = await getAutoCheckoutList(requestBody,userSession,org_id,sub_org_id).then(res=>res.data);      
          
          let attendanceData = generateWorkerAttendance(mapList,empUnFormated, getDateFormat(startDate),getDateFormat(endDate),start_hour,end_hour,addDay )
          console.log("attendanceData",attendanceData,attendanceData.reduce((old,current)=>old+ current.total_count ,0))
          setRowData(attendanceData.filter(el=>el.total_count >0));
          const dateRange = [];
        let currentDate = new Date(startDate);
        let end = new Date(endDate);

        while (currentDate <= end) {
            dateRange.push(currentDate.toISOString().split("T")[0]);
            currentDate.setDate(currentDate.getDate() + 1);
        }
            const columns = [
            { headerName: "Name", field: "name", pinned: "left", width: 150 },
            { headerName: "Worker ID", field: "worker_id", pinned: "left",width: 150 },
            // { headerName: "Tag Serial", field: "tag_serial",  pinned: "left",width: 150 },
            { headerName: "Total Count", field: "total_count", pinned: "left",width: 150 },
            ...dateRange.map(date => ({
                headerName: moment(date).format('DD MMM'),
                field: date,
                width: 100,
                cellRenderer: (params)=>iconRender(params),
            }))
        ];

        setColumnDefs(columns);
          let empList=[]

          if(attendanceData.length > 0){
            SetFilterData(attendanceData.filter(el=>el.total_count >0));
         
          }else{
            SetFilterData([]);
            toast.error("Something Went Wrong While Fetching Attendance Time");
          }
          
              SetLoader(false);
            if(empList.status===200){
              
            }else{
              
              SetLoader(false);
            }

          }
    }catch(error) {
            console.log("error",error)
          } 
    }
    
    filterResultHandler()

},[props.filterPassData, props.filterFOP]);


useEffect(()=>{
  if(props.exportEnable){
    generateExcelFile(filterData);
  }
},[props.exportEnable,filterData])

 const generateExcelFile = async (data) => {
    const workbook = new ExcelJS.Workbook();
    const imageId = await getImageId(workbook, TraceLogo);
    
        await generateSheet(workbook, "Auto Checkout",data ,imageId);
    
  const fileName = 'AutoCheckoutReport '+moment(props.filterPassData.date).format('YYYY-MM-DD');
    const writeFile = (fileName, content) => {
              const link = document.createElement("a");
              const blob = new Blob([content], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;"
              });
              link.download = fileName;
              link.href = URL.createObjectURL(blob);
              link.click();
            };
          //const stream = fs.createWriteStream(filename);
          
          workbook.xlsx.writeBuffer().then((buffer) => {
              writeFile(fileName, buffer);
          });
      props.exportFalseFOP();
  };

  function valueRemoveNull(val){
    if(isNaN(val)){
      return 0
    }else if(val===null || val===undefined){
      return 0
    }else{
      return val
    }

  }

    const generateSheet = async (workbook, sheetName, data, logoID) => {
    const worksheet = workbook.addWorksheet(sheetName);

    // Add logo
    worksheet.addImage(logoID, {
        tl: { col: 0, row: 1 },
        ext: { width: 153, height: 34 },
    });

    // Add empty rows for spacing
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.mergeCells('A1:B4');
    worksheet.addRow([]);
worksheet.addRow([]);
    // Add header details
    worksheet.addRow(['Date', moment(props.filterPassData.date).format('YYYY-MM-DD')]).getCell(2).font = { bold: true };
    worksheet.addRow(['Auto Checkout', "Date"]).getCell(2).font = { bold: true };
    worksheet.addRow(['Site', userDetails.org_name]).getCell(2).font = { bold: true };

    worksheet.addRow([]);

    // Generate date range
    const dateRange = [];
    let currentDate = new Date(props.filterPassData.start_date);
    let end = new Date(props.filterPassData.end_date);

    while (currentDate <= end) {
        dateRange.push(currentDate.toISOString().split('T')[0]); // 'YYYY-MM-DD'
        currentDate.setDate(currentDate.getDate() + 1);
    }

    // Prepare table data
    const tableData = data.map(item => [
        item.name,
        item.worker_id,
        item.total_count,
        ...dateRange.map(date => item[date] === "absent" ? " - " : item[date])
    ]);

    // Create table
    worksheet.addTable({
        name: sheetName.replaceAll(' ', '_'),
        ref: `A12`,
        columns: [
            { name: 'Name' }, 
            { name: 'Worker ID' }, 
            { name: 'Total Count' }, 
            ...dateRange.map(date => ({ name: moment(date).format('DD MMM') }))
        ],
        rows: tableData,
    });

    const table = worksheet.getTable(sheetName.replaceAll(' ', '_'));

    // Auto-adjust column widths
    worksheet.columns.forEach(column => {
        const lengths = column.values.map(v => v ? v.toString().length : 1);
        const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
        column.width = maxLength + 2;
    });

    // Apply text color styling based on values (Green for "yes", Red for "no")
    tableData.forEach((row, rowIndex) => {
    row.slice(3).forEach((cellValue, colIndex) => {
        const excelRow = rowIndex + 13; // Adjusted to match actual row in Excel
        const excelCol = colIndex + 4;  // Adjust to match starting column after "Total Count"
        const cell = worksheet.getRow(excelRow).getCell(excelCol);
        
        if (cellValue === "yes") {
            cell.font = { color: { argb: "008000" } }; // Green
        } else if (cellValue === "no") {
            cell.font = { color: { argb: "FF0000" } }; // Red
        }
    });
});

    // Commit table changes
    table.commit();
};

  

  const getImageId = async (workbook, imageUrl) => {
    const response = await fetch(imageUrl);
    const arrayBuffer = await response.arrayBuffer();
    const base64Image = Buffer.from(arrayBuffer).toString('base64');

    return workbook.addImage({
      base64: base64Image,
      extension: 'png', // Change the extension based on your image format
    });
  };

  function capitalizeFirstLetter(string) {
      if(string){
        return string.toLowerCase();
      }
  }

  function AGGridEMptyMEssage(key){
    let msg;
        msg='<span class="ag-overlay-no-rows-center">No Records Found !</span>'
    return msg
  }


const compressedTableStyles = {
  margin: 0,
  padding: 0,
};
const compressedRowStyles = {
  lineHeight: '1.2rem', // Adjust the line height as needed
};


 const getImageSize = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
      toast.error("Fail to export please again after sometime");
    }
  const blob = await response.blob();
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.src = URL.createObjectURL(blob);
  });
};


function timeStringToMilliseconds(timeString) {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  return ((hours * 60 + minutes) * 60 + seconds) * 1000;
}

function millisecondsToTimeString(milliseconds) {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}

function valueRenderTime(params){
        var op =document.createElement('div');

        var eGui = document.createElement('div');
        if(params.value ==="" || params.value ==="00:00:00"){
          eGui.innerHTML=`<div class="sucessColor"> - </div>`;
        }
        else if(params.data['Work_time_status'] === 'Over-time' || params.data['Work_time_status'] === 'complied' ){
          eGui.innerHTML=`<div class="sucessColor">${params.value}</div>`;
        }else{
          eGui.innerHTML=`<div class="redColor">${params.value}</div>`;
        }
          
      
      return eGui;  
}



function valueRenderTimeExcel(params){

        const actualWorkHoursMs = timeStringToMilliseconds(params.actual_work_hours);
const planWorkHoursMs = timeStringToMilliseconds(params.plan_work_hours);

const differenceMs = actualWorkHoursMs - planWorkHoursMs;
const differenceTimeString = millisecondsToTimeString(Math.abs(differenceMs));
const differenceSign = differenceMs >= 0 ? '' : '-';

        


      return `${differenceSign}${differenceTimeString}`;  
}
 

  function AGTablePlanActual(data){    
      

        let arr;
        let date='';

        arr=<div className={"keyAGTableSmallPlan keyAGTableSmallPlanHeader"}><div className="ag-theme-alpine if cell-size-40" style={{height:(rowData.length*41.5)+100, width: "calc(100% - 1px)"}}>

 <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={{ resizable: true, sortable: true, filter: true }}
            />      </div></div>
      
      return arr
    }


     return (props.filterActive?
      loader?                                <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:
    <div className="App">
    <ToastContainer/>
    <Row>
      <Col lg="12"><h4>Attendance Report:  <span style={{color:"#ef5e8c"}}>Auto Checkout</span></h4></Col>
      
    </Row>
      {AGTablePlanActual(filterData)}

    </div>:""
    )

}