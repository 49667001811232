import React, { useState, useEffect,useRef } from 'react'
import { Container, Row, Col } from "react-bootstrap";
import { CommonHeading } from "../../common/commonHeading.js";
//import { cameraPlacements } from  '../hooks/_data'
import spinnerLoader from "../../assets/images/loader.svg";
import moment from "moment";
import {
  getLanguageTranslation
} from "../../dashboard/actionMethods/actionMethods.js";
import DashboardLanguage from "../../components/dashboardLanguage.js";
import CommonDatePicker from "../../common/commonDatePicker.js";

import { Slider} from 'antd';
import { Select } from 'antd';
import LocationIcon from  '../../assets/images/location.png'

import {getPproductivitylistDetail } from "../../productivity/actionMethods/actionMethods.js";
// import {getEmployeeTracker} from '../../employeeTracker/actionMethods/actionMethod';

import { getPproductivityDetailOverview,
  getIndividualProductivityList } from '../../productivity/actionMethods/actionMethods.js';

  import {ReactComponent as Image404 } from '../../assets/images/11132-ai.svg'
import '../../employeeTracker/style/style.scss'

import '../../realtimeTracker/style/style.scss'
import Scrollbars from 'react-custom-scrollbars';
import 'antd/dist/antd.css';
import { useScript, useStyle } from './useScriptAndStyle.js';
import { getLocationCordinateOld, getLocationList } from '../actionMethods/actionMethods.js';
import Light from '../../assets/images/light.png';
import Street from '../../assets/images/street.png';
import Satellite from '../../assets/images/satellite.png';
import Outdoors from '../../assets/images/outdoor.png';
import Dark from '../../assets/images/dark.png';
import '../style/styles.scss';

import ManProductiveIcon from '../../assets/images/man.png'

import { getLocationCordinate ,getEmployeeTracker} from '../actionMethods/actionMethods.js';
import { map } from 'd3';
import MapIcon from '../../assets/images/placeholder-map.png';


let dataUniqueLocation=[];
let previousTag={};
let biDirectional=[];

let layer=[];
let dataPush=[];
const MapboxTrackerDetail = (props) => {
  const { Option } = Select;
  

  const [locationCordinate,SetLocationCordinate]= useState([]);
  const [slug,SetSlug] =useState('');
  const [error,SetError]=useState('');
  
  let date = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();

  const [selectedDate, updateSelectedDate] = useState(date);
  const [selectedLangValue, updateSelectedLangValue] = useState("en");
//const slideTooltip=useRef()
  const slideTooltip = useRef(null);
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const existingPolygons = useRef([]);
  const [selectedStyle, setSelectedStyle] = useState('mapbox://styles/mapbox/streets-v8'); // Default style
  const [locationList, SetLocationList] = useState([]);
  const [currentMode, setCurrentMode] = useState('Streets');
  const [currentModeImg, setCurrentModeImg] = useState(Street);
  const [userMovement,SetUserMovement] = useState([]);


  const [width, setWidth] = useState(0);  
  
  const [permission,SetPermission] =useState([])
  const [permissionAPICall,SetPermissionAPICall] = useState(false);
  const [department,SetDeparment] =useState([]);
  const [department_id,SetDepartmentID]=useState('');
  
  const [sub_department,SetSubDeparment] =useState([]);
  const [sub_department_id,SetSubDepartmentID]=useState('');
    
  const [employeeID,SetEmployeeID]=useState('');
  const [tag_serial,SetTagSerial] = useState('')
  const [employeeAll,SetEmployeeAll]=useState([]);
  const [employeeDefault,SetEmployeeDefault]=useState([]);
  const [loaderEmp,SetLoaderEmp]=useState(false)
  const [start_time,SetStartTime]=useState(props.match.params && props.match.params.hour?[props.match.params.hour]:[]);
  const [end_time,SetEndTime]=useState('');
  const [isloading,Setloading] =useState(true);
  
  const [autoPlay,SetAutoPlay] =useState(false);

  let userDetails = JSON.parse(localStorage.getItem("userLoginDetails"));

  let sub_org_id  =userDetails && userDetails.sub_org_id ? userDetails.sub_org_id : 0;
  let userSession = userDetails ? userDetails.session : "123456789";
  let org_id = userDetails ? userDetails.org_id : 0;
  let lastReqId = null
  
  const [shiftStarttime,SetShiftStartTime] =useState('');
  const[ ShiftEndtime,SetShiftEndTime]=useState('');
  const [shiftBasedDropdown,SetShiftBasedDropdown] =useState([]);  
  const [activeHourDropdown,SetActiveHourDropdown] = useState([]);
  
  const [filterTimeData,SetFilterTimeData] =useState([]);
  const [sucessMessage,SetSucessMessage] =useState(false);

  const [dailyEmpDefault,SetDailyEmpDefault] =useState([]);
  const [dailyEmpDepartment,SetDailyEmpDepartment] =useState([]);
  const [regularEmpDepartment,SetRegularEmpDepartment] =useState([]);
  const [enable,SetEnable] =useState(false);
  const [startBigger,SetStartBigger]=useState(false);  
  
  const elementRef =useRef(null);
  const [heightMap,SetHightMap]= useState(0);
  const [dualLocationList,SetDualLocationList]=useState([])
  const [oldPosition,SetOldPosition]=useState(0);
  const [lagFreeFlag,SetLagFreeFlag]=useState(false);
  const [selectedStartTime,SetSelectedStartTime]=useState([])
  const [widthBox, SetWidthBox] = useState(60);
  const [locationListMapBox,SetLocationListMapBox] =  useState([]);
  const [loader,SetLoader]=useState(true);
  const markersRef = useRef([]);
  const activePopupRef = useRef(null);
    const [centroidMap,SetCentroidMap]= useState([]);
    let dummyCentroid= {
    "type": "Feature",
    "properties": {},
    "geometry": {
      "type": "Point",
      "coordinates": []
    }
  }

  const timeZone = userDetails?.organization_timezone?.organization_timezone_name || 'Asia/Jakarta';    
  let addDay = props.workday && props.workday.start_hour==="00:00:00"?0:1  
  const mapboxtoken = process.env.REACT_APP_MAPBOX_TOKEN

    const changeMapStyle = (newStyle, title, image) => {
        setSelectedStyle(newStyle);
        setCurrentMode(title);
        setCurrentModeImg(image);
        setTimeout(() => SetWidthBox(60), 500);

        if (mapRef.current) {
            mapRef.current.setStyle(newStyle);
        }
    };
    
    const mapBoxMode = [
        { function: 'mapbox://styles/mapbox/streets-v8', title: "Streets", image: Street },
        { function: 'mapbox://styles/mapbox/outdoors-v8', title: "Outdoors", image: Outdoors },
        { function: 'mapbox://styles/mapbox/light-v10', title: "Light", image: Light },
        { function: 'mapbox://styles/mapbox/dark-v10', title: "Dark", image: Dark },
        { function: 'mapbox://styles/mapbox/satellite-v9', title: "Satellite", image: Satellite }
    ];

    useScript('https://api.mapbox.com/mapbox-gl-js/v3.7.0/mapbox-gl.js');
    useStyle('https://api.mapbox.com/mapbox-gl-js/v3.7.0/mapbox-gl.css');
    useScript('https://cdnjs.cloudflare.com/ajax/libs/Turf.js/6.5.0/turf.min.js');

    useEffect(() => {
        if (window.mapboxgl && !loader && mapContainerRef.current && centroidMap.length != 0) {
            window.mapboxgl.accessToken = mapboxtoken;

            // Initialize the map
            mapRef.current = new window.mapboxgl.Map({
                container: mapContainerRef.current,
                style: selectedStyle,
                center:centroidMap || [100.58317249183762, 13.72696169281258], // Example coordinates
                zoom: 17,
                projection: 'globe',
                pitch: 10,
                bearing: -15.6,
            });

            const nav = new window.mapboxgl.NavigationControl();
            mapRef.current.addControl(nav, 'top-right');
        }

        return () => {
            if (mapRef.current) {
                mapRef.current.remove();
            }
        };
    }, [selectedStyle, loader, locationList, locationCordinate,centroidMap]);

   const getMapCenterUsingTurf = (locations) => {
      // Convert locations to GeoJSON FeatureCollection
      console.log("locations",locations)
      const features = locations.map(loc => 
        window.turf.point([parseFloat(loc.position.x), parseFloat(loc.position.z)])
      );

      const featureCollection = window.turf.featureCollection(features);

      // Compute the center using Turf.js
      const center = window.turf.center(featureCollection); // or use turf.centerMean(featureCollection)
      console.log("center",center);
      return {
        lat: center.geometry.coordinates[1], 
        lng: center.geometry.coordinates[0]
      };
};

    //     useEffect(() => {
    //     const checkTurfLoaded = setInterval(() => {
    //         if (window && window.turf) {
    //             clearInterval(checkTurfLoaded);

    //             const requestBody = {
    //                 session: userDetails.session,
    //                 org_id: userDetails.org_id,
    //             };
    //             getLocationList(requestBody)
    //                 .then(res => {
    //                     if (res.status === 200 || res.code === 200) {
    //                         SetLocationListMapBox(res.data);
                            
    //                         getLocationCordinate(requestBody).then(res3 => {
    //                           if (res3.status === 200) {
    //                               const locList = res3.data.map(el => {
    //                                   const polygonFeature = window.turf.polygon([el.coordinates]);
    //                                   const centroid = window.turf.centroid(polygonFeature);
    //                                   const allDetail=  res.data.find(elm=>elm.tag_serial === el.location_serial)

    //                                   return { ...el, centroid: centroid,...allDetail};

    //                               });

    //                               SetLocationCordinate(locList);
    //                               setTimeout(function(){SetLoader(false);},200);
    //                               Setloading(false);
                                  
    //                           }
    //                         }).catch(error => console.error("Error fetching location coordinates:", error));
    //                     }
    //                 })
    //                 .catch(error => console.error("Error fetching location list:", error));
    //         }
    //     }, 100);

    //     return () => clearInterval(checkTurfLoaded);
    // }, []);
  useEffect(() => {
    const checkTurfLoaded = setInterval(async () => {
        if (window && window.turf) {
            clearInterval(checkTurfLoaded);

            try {
                const requestBody = {
                    session: userDetails.session,
                    org_id: userDetails.org_id,
                };
                

                const res = await getLocationList(requestBody);

                const res4 = await getLocationCordinateOld(requestBody);

                const olldLocation = res4?.data?.location || []
                console.log("res4",res4, olldLocation);
                if (res.status === 200 || res.code === 200) {
                    SetLocationListMapBox(res.data);
                    try {
                        const res3 = await getLocationCordinate(requestBody);
                        let ctMap = getMapCenterUsingTurf(olldLocation)
                        console.log("ctMap",ctMap,ctMap.lng,ctMap.lat)
                        SetCentroidMap([ctMap.lng,ctMap.lat])
                          // let centroidMap = window.turf.polygon(res3.data.map(el=>[el.coordinates]));
                        if (res3.status === 200) {
                            const locList = res3.data.map(el => {
                                const polygonFeature = window.turf.polygon([el.coordinates]);
                                let centroid={}
                                let currentLoc = olldLocation.find(elm=>elm.tag_serial === el.location_serial);

                                if(currentLoc){
                                  let ct = {...dummyCentroid};
                                  ct.geometry.coordinates=[currentLoc.x,currentLoc.z]
                                 centroid = ct;
                                }else{
                                  centroid = window.turf.centroid(polygonFeature);  
                                }
                                
                                const allDetail = res.data.find(elm => elm.tag_serial === el.location_serial);
                                console.log("Centroid", allDetail, centroid);

                                return { ...el, centroid, ...allDetail,...currentLoc };
                            });

                            SetLocationCordinate(locList);
                            setTimeout(() => SetLoader(false), 200);
                            Setloading(false);
                        }
                    } catch (error) {
                        console.error("Error fetching location coordinates:", error);
                    }

                }
            } catch (error) {
                console.error("Error fetching location list:", error);
            }
        }
    }, 100);

    return () => clearInterval(checkTurfLoaded);
}, []);
        useEffect(() => {

        if (locationCordinate.length > 0 && window.mapboxgl && mapRef.current && !loader) {
            locationCordinate.forEach(location => {
                const coordinates = location.coordinates;
                
                let loc = locationListMapBox.find(el => el.tag_serial === location.location_serial);


                mapRef.current.on('style.load', () => {
                  plotLocationMarkers([location]);              
                    existingPolygons.current.push([coordinates]);
                    mapRef.current.addLayer({
                        id: `outline-${loc.id}`,
                        type: 'line',
                        source: {
                            type: 'geojson',
                            data: {
                                type: 'Feature',
                                geometry: {
                                    type: 'Polygon',
                                    coordinates: [coordinates]
                                },
                            },
                        },
                        layout: {},
                        paint: {
                            'line-color': '#4ec274',
                            'line-width': 1
                        }
                    });
                    // addPolygonWithLabel([coordinates], loc.location_name, loc.id, location.centroid);
                });
            });
        }
        
    }, [locationCordinate, loader, locationListMapBox,selectedStyle]);
    
    function addPolygonWithLabel(polygonCoordinates, labelText, locationId, centroid) {
        const lngLat = centroid.geometry.coordinates;
        // new window.mapboxgl.Marker({ color: 'red' }).setLngLat(lngLat).addTo(mapRef.current);
        mapRef.current.addSource(`label-${locationId}`, {
            type: 'geojson',
            data: {
                type: 'Feature',
                geometry: {
                    type: 'Point',
                    coordinates: centroid.geometry.coordinates,
                },
                properties: {
                    title: labelText,
                },
            },
        });

        mapRef.current.addLayer({
            id: `label-layer-${locationId}`,
            type: 'symbol',
            source: `label-${locationId}`,
            layout: {
                'text-field': ['get', 'title'],
                'text-size': 10,
                'text-offset': [0, 0.5],
                'text-anchor': 'top',
            },
            paint: {
                'text-color': '#000000',
            },
        });
    }


  useEffect(() => {
    if(elementRef && elementRef.current){    
      SetHightMap(elementRef.current.getBoundingClientRect().height);
      setWidth(elementRef.current.offsetWidth);
    }
  
  }, [elementRef,isloading]);

useEffect(()=>{
if(props.match.params && props.match.params.tag ){
  let useDaily= dailyEmpDefault.find(item=>item.tag_serial==props.match.params.tag);  
  if(useDaily){
    SetSlug('daily_worker')
    SetDepartmentID(useDaily.department)
  }else{
    let useRegular = employeeAll.find(item=>item.tag_serial==props.match.params.tag);  
    SetSlug('employee');
    if(useRegular){
      SetDepartmentID(useRegular.department)
    }
  }


}
},[props.match.params,employeeAll,dailyEmpDefault])

useEffect(() => {
    setTimeout(function(){
      SetLagFreeFlag(true);
    },3000)
  }, []);

  useEffect(() => {
    let perm= props.pages.filter(el=>el.sub_page_name && el.sub_page_name =="Employee Tracker Mapbox"); 
    if(perm){
        SetPermission(perm)
        SetPermissionAPICall(true)
    }else{
    }
},[props.pages]);

function isInt(value) {
  return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
}

      useEffect(()=>{
          let data = [...locationCordinate] 

        if(data.length>0){
        
          if(props.start_time.length > 0){
                  SetStartTime(props.start_time);
                  let st = props.start_time.map(el=>props.activeHourDropdown.find(item=>item.hour == el) );
                  console.log("stststst",st);
                  filterResultHandler(props.employeeID,st,startBigger,date)
       
          }        
            
          }
        

      },[props.start_time,props.tag_serial,props.activeHourDropdown,locationCordinate])


    function fetchAllHour(start,end,data){
        const startDate = start;
        const endDate = end; // Assuming end_time is on the next day

        const hourArray = [];
        Object.keys(data).forEach(element => {
            Object.keys(data[element]).forEach(key2=>{
                hourArray.push({hour:parseInt(key2).toString() ,date:element,sortTime:element+" "+key2+":00:00"});
            });        
        });
        let sortedTIme = hourArray.sort((a,b)=>new Date(a.sortTime) - new Date(b.sortTime))
        return sortedTIme;

    }
    
useEffect(()=>{ 


},[permission,employeeID,start_time,department_id,sub_department_id,slug])

function removeLayerSmplrSpace(){
  
    
    }



  function handleDateSelect(date) {
    updateSelectedDate(date);
    SetSlug('');
    SetStartTime([]);

      removeLayerSmplrSpace()    
             setCount(0);
           SetSucessMessage(false);
           SetLoaderEmp(false);
       SetDefaultPoly([])
    let startDate = new Date().setDate(new Date().getDate() - 29);
    let endDate = new Date(moment(date).format('YYYY-MM-DD 23:59:59'));

    //setChartDates({ start: startDate, end: endDate });
  }

  function AddDataLayer(data){
}

function convertTimeZone(time){
 const utcTimeString = time;
 
const utcDateTime = new Date(utcTimeString + "Z"); // Append 'Z' to indicate UTC time
const indonesiaTimeZone = userDetails?.organization_timezone?.organization_timezone_name || 'Asia/Jakarta';  
const indonesiaOptions = { timeZone:  indonesiaTimeZone };
const indonesiaTimeString = utcDateTime.toLocaleString("en-US", indonesiaOptions);
return moment.utc(indonesiaTimeString).format('hh:mm:ss A')

} 

  function updateDataLayer(data,url,width,widthSize,inc){

  }

  function filterResultLocation(name){

  }
  

  
  function getDateFormat(date) {
    return moment(date).format("YYYY-MM-DD");
  }
    
  const  [marks,SetMark]  = useState([]) 
  const [marks2,SetMark2] =useState([]) 
  






  const [defaultPoly,SetDefaultPoly] = useState([])

  const [currentCount, setCount] = useState(0);
  const randomcolor = ['#000','#d95780','#51ac30','#101535','#b8cdda','#ffa500','#ff0000']

  

  const timer = () => {
    setCount(currentCount + 1);    
  }

        
    useEffect(() => {
      if (props.language) {
        updateSelectedLangValue(props.language);
      }
    }, [props.language]);

    
  
  
    const handleDate = (date, type) => {
      // setChartDates((prev) => ({ ...prev, [`${type}`]: date }));
    };




    function HandleRange(e){
      setCount(e);
    }

   function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }


  const plotLocationMarkers = (locations) => {
  locations.forEach((location) => {
    const { position, name, unique_loc_id, location_id } = location;
    console.log("position plotLocationMarkers", position);

    if (!position || isNaN(position.x) || isNaN(position.z)) {
      console.error(`Invalid position for ${name} (${unique_loc_id}):`, position);
      return; // Skip this location
    }

    const lng = parseFloat(position.x);
    const lat = parseFloat(position.z);

    if (isNaN(lng) || isNaN(lat)) {
      console.error(`Invalid coordinates for ${name} (${unique_loc_id}):`, position);
      return;
    }

    // Create a marker element with a custom icon
    const markerElement = document.createElement('div');
    markerElement.className = 'custom-marker';
    markerElement.style.backgroundImage = `url('${MapIcon}')`; // Set your icon path
    markerElement.style.backgroundSize = 'cover';
    markerElement.style.width = '10px';
    markerElement.style.height = '10px';

    // Add the marker to the map
    const marker = new window.mapboxgl.Marker(markerElement)
      .setLngLat([lng, lat])
      .setPopup(
        new window.mapboxgl.Popup({ offset: 25 }).setHTML(`<strong>${name}</strong>`)
      ) // Tooltip content
      .addTo(mapRef.current);

    // Remove the existing layer first (if it exists)
    if (mapRef.current.getLayer(`label-layer-${location_id}`)) {
      mapRef.current.removeLayer(`label-layer-${location_id}`);
    }

    // Remove the existing source (after the layer is removed)
    if (mapRef.current.getSource(`label-${location_id}`)) {
      mapRef.current.removeSource(`label-${location_id}`);
    }

    // Add new source
    mapRef.current.addSource(`label-${location_id}`, {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [lng, lat],
        },
        properties: {
          title: name,
        },
      },
    });

    // Add new layer
    mapRef.current.addLayer({
      id: `label-layer-${location_id}`,
      type: 'symbol',
      source: `label-${location_id}`,
      layout: {
        'text-field': ['get', 'title'],
        'text-size': 10,
        'text-offset': [0, 0.5],
        'text-anchor': 'top',
      },
      paint: {
        'text-color': '#000000',
      },
    });

    markersRef.current[unique_loc_id] = marker; // Store marker by location ID
  });
};
async function filterResultHandler(tag = employeeID, stimeInput = start_time, bigTimer = startBigger,tag_serial) {

  console.log("all props",props);
  let dataUniqueLocation = [];
  let dualLocation = [];
  let arr = [];
  let mk = [];
  let locationPoint = [...locationCordinate,{
  "coordinates": [],
  "id": 'Out of Boundary',
  "location_serial": "Out of Boundary",
  "org_id": 36,
  "properties": {},
  "centroid": {
    "type": "Feature",
    "properties": {},
    "geometry": {
      "type": "Point",
      "coordinates": []
    }
  },
  "area_size": 5,
  "capacity": 5,
  "categories": "Outdoor",
  "description": "Out of Boundary",
  "loc_type": 1,
  "location_name": "Out of Boundary",
  "tag_serial": "Out of Boundary"
},{
  "coordinates": [],
"id": 'Out of Range',
  "location_serial": "Out of Range",
  "org_id": 36,
  "properties": {},
  "centroid": {
    "type": "Feature",
    "properties": {},
    "geometry": {
      "type": "Point",
      "coordinates": []
    }
  },
  "area_size": 5,
  "capacity": 5,
  "categories": "Outdoor",
  "description": "Out of Range",
  "loc_type": 1,
  "location_name": "Out of Range",
  "tag_serial": "Out of Range"
}];  
  SetLoaderEmp(true);
  setCount(0);
  SetError('');
  SetFilterTimeData([]);
  SetSucessMessage(false);
  const stime = stimeInput
props.loaderStart();


  const promises = stimeInput.map(async (timeObj, index) => {
    const requestBody = {
      worker_id: tag,
      date: timeObj.date ? getDateFormat(timeObj.date) : getDateFormat(selectedDate),
      start_time: `${parseInt(timeObj.hour || start_time[index])}:00:00`,
      end_time: `${parseInt(timeObj.hour || start_time[index]) === 23 ? "00" : parseInt(timeObj.hour || start_time[index]) + 1}:00:00`,
      work_day: moment(selectedDate).format("YYYY-MM-DD"),
    };

    try {
      const res = await getEmployeeTracker(requestBody, userSession, org_id, sub_org_id);
      if (res.status === 200) {
        let data = res.data[props.tag_serial] ? res.data[props.tag_serial] : res.data;
        
        if (data.length > 0) {
          data = data.sort((a, b) => moment(a.timestamp) - moment(b.timestamp));
        }

        let previousLocation = [];
        let incCount = 0;
        data.forEach((entry) => {

          const key = entry.location_serial;
          const findPos = locationPoint.find(item => item.location_serial === key);

          if (findPos) {
            mk.push(moment.utc(entry.timestamp));
            // moment.utc(item.time_spent).format("HH:mm:ss")
            if (!dataUniqueLocation.includes(findPos.location_name)) {
              dataUniqueLocation.push(findPos.location_name	);
            }

            const polylineFormat = {
              levelIndex: 0,
              x: entry.location[0],
              z: entry.location[1],
              elevation: 5,
              name: findPos.location_name	,
              tag_serial: findPos.location_serial,
              time: entry.timestamp,
              position: incCount,
            };
            arr.push(polylineFormat);

            if (previousLocation.length > 0 && previousLocation[previousLocation.length - 1].tag_serial !== findPos.location_serial) {
              const oldLoc = previousLocation[previousLocation.length - 1];
              const newLoc = polylineFormat;

              dualLocation.push({
                old: { ...oldLoc },
                new: { ...newLoc },
                position: incCount,
              });
            }
            previousLocation.push(polylineFormat);
            incCount++;
          }
        });
      }
    } catch (error) {

    }
  });

  // Wait for all promises to complete
  await Promise.all(promises);
    

  // After all async operations, update the state with collected data
  arr = arr.sort((a, b) => new Date(a.time) - new Date(b.time));
  mk = mk.sort((a, b) => new Date(a) - new Date(b)).map(item => moment(item).utc().format('hh:mm:ss A'));


let movement = arr.map(el=>{
                 return ({location:[el.x,el.z],timestamp:el.time,name:el.name,tag_serial:el.tag_serial})
                })
console.log("movement",movement);
  SetUserMovement(movement)
  SetDefaultPoly(arr);
  SetDualLocationList(dualLocation);
  SetSucessMessage(true);
  SetOldPosition(0);
  setCount(arr.length);
  SetMark(mk);
  SetLoaderEmp(false);
  props.loaderEnd();

  SetSelectedStartTime(stime);
}

    function getInstructions(data) {
      
      let tripDirections = '';
      // Output the instructions for each step of each leg in the response object
      for (const leg of data.legs) {
        const steps = leg.steps;
        for (const step of steps) {
          tripDirections += `<li>${step.maneuver.instruction}</li>`;
        }
      }
      // directions.innerHTML = `<p><strong>Trip duration: ${Math.floor(
      //   data.duration / 60
      // )} min.</strong></p><ol>${tripDirections}</ol>`;

      console.log("data direction",data,`${Math.floor(
        data.duration / 60
      )} min ${tripDirections}`);
    }

    async function getMatch(coordinates, radius, profile,dataToShow,timeData) {

 const lineCoordinates = []; // To store coordinates for the path line
dataToShow.forEach((movement, index) => {
      const { location, timestamp } = movement;

            let length = dataToShow.filter(elm=>elm.tag_serial  === movement.tag_serial).length;
            const el = document.createElement('div');
           
            const width = 15;
            const height =25;
            el.className = 'marker';
            el.style.backgroundImage = `url(${ManProductiveIcon})`;
            el.style.width = `${width}px`;
            el.style.height = `${height}px`;
            el.style.backgroundSize = '100%';
            // new window.mapboxgl.Marker(el).setLngLat(point).addTo(mapRef.current);
      let tooltipText= `${movement.name}`;

      if(timeData[index]!== undefined){
        
        tooltipText+=` (${convertTimeZone(timeData[index].time)})`
      }

        const popup = new window.mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        }).setText(`${tooltipText})`);

        const marker = new window.mapboxgl.Marker(el)
            .setLngLat(location)
            .setPopup(new window.mapboxgl.Popup().setText(`${movement.name}`)) // Optional popup
            .addTo(mapRef.current);
        marker.getElement().addEventListener('mouseenter', () => {
            popup.setLngLat(location).addTo(mapRef.current);
            activePopupRef.current = popup;
        });
        marker.getElement().addEventListener('mouseleave', () => {
            popup.remove();
        });     
        markersRef.current.push(marker);


      
      lineCoordinates.push(location);
      

      // Draw the line incrementally
      if (index > -1 && lineCoordinates.length > 1) {
        // Remove any existing line
        if (mapRef.current.getSource('route')) {
          mapRef.current.removeLayer('route');
          mapRef.current.removeSource('route');
        }

        // Add GeoJSON line source
        mapRef.current.addSource('route', {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: lineCoordinates,
            },
          },
        });

        // Add line layer
        mapRef.current.addLayer({
          id: 'route',
          type: 'line',
          source: 'route',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#888',
            'line-width': 4,
          },
        });
      }
    });

    const chunkSize = 60; // Define the size of each chunk
  
    let allLineCoordinates = []; // 
    const chunkArray = (arr, size) => {
      const result = [];
      for (let i = 0; i < arr.length; i += size) {
        result.push(arr.slice(i, i + size));
      }
      return result;
    };
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const coordinateChunks = chunkArray(coordinates, chunkSize);
    const radiusChunks = chunkArray(radius, chunkSize);

     for (let i = 0; i < coordinateChunks.length; i++) {
    const coords = coordinateChunks[i].join(';');
    const radiuses = radiusChunks[i].join(';');
    const url = `https://api.mapbox.com/matching/v5/mapbox/${profile}/${coords}?geometries=geojson&radiuses=${radiuses}&steps=true&access_token=${mapboxtoken}`;

    try {
      // Fetch map-matching data
      const response = await fetch(url, { method: 'GET' });
      const data = await response.json();

      // Check if response is successful
      if (data.code === 'Ok') {
        const coords = data.matchings[0].geometry.coordinates;
        allLineCoordinates.push(...coords); // Aggregate coordinates for the final route
      } else {
        console.warn(`Map-matching error for chunk ${i + 1}: ${data.message}`);
      }
    } catch (error) {
      console.error(`Error fetching map-matching data for chunk ${i + 1}:`, error);
      // Skip this chunk and proceed to the next
    }

    // Delay to avoid hitting rate limits
    await delay(200); // Adjust delay time as needed (200ms is an example)
  }


 
if (allLineCoordinates.length > 0) {
  console.log("allLineCoordinates",allLineCoordinates)
      addRoute(allLineCoordinates);
}

      
      
      }



    
    
    function updateRoute(coords,dataToShow,timeData) {
      removeRoute(); 
      const profile = 'walking'; // Set the profile
      const newCoords = coords.join(';');
      const radius = coords.map(() => 5);
      getMatch(coords, radius, profile,dataToShow,timeData);
    }

    // If the user clicks the delete draw button, remove the layer if it exists
    function addRoute(coords) {
  // Ensure `coords` is an array of coordinate pairs
  if (!Array.isArray(coords) || coords.length === 0) {
    console.error("Invalid coordinates provided to addRoute");
    return;
  }

  // Format the data as a GeoJSON Feature object
  const geojsonData = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: coords,
    },
  };

  // If a route is already loaded, remove it
  if (mapRef.current.getSource('routematch')) {
    mapRef.current.removeLayer('routematch');
    mapRef.current.removeSource('routematch');
  }

  // Add a new source and layer for the route
  mapRef.current.addSource('routematch', {
    type: 'geojson',
    data: geojsonData,
  });

  mapRef.current.addLayer({
    id: 'routematch',
    type: 'line',
    source: 'routematch',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': '#03AA46',
      'line-width': 8,
      'line-opacity': 0.8,
    },
  });
}

    function removeRoute() {
      if (!mapRef.current.getSource('routematch')) return;
          mapRef.current.removeLayer('routematch');
          mapRef.current.removeSource('routematch');
      }

useEffect(() => {
  if (!loader && userMovement.length > 0 && mapRef && mapRef.current && defaultPoly.length > 0) {
    
    const dataToShow = userMovement.slice(0, currentCount); // Limit to specified count
    const timeData = defaultPoly.slice(0,currentCount);
    SetFilterTimeData(timeData)
   
    

    markersRef.current.forEach(marker => marker.remove());
    markersRef.current = [];
        if (mapRef.current.getSource('route')) {
          mapRef.current.removeLayer('route');
          mapRef.current.removeSource('route');
        }

      updateRoute(dataToShow.map(el=>el.location),dataToShow,timeData)  
    
  }
}, [dualLocationList, userMovement, currentCount, loader,defaultPoly]);




  function formatAMPMHour(date) {
    var hours = date;
    var minutes = 0;
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  function onChangeStartTime(val){

    let dept= shiftBasedDropdown;
  
    if(val.indexOf('all')> -1){
        let value =dept;
        if((val.length -1) == value.length ){
          SetStartTime([])
        }else{
          SetStartTime(value);
        }
    }else{
      SetStartTime(val);
    }
  }
  
  return (<>
    {permissionAPICall?permission.length > 0?<>
   
                    {loaderEmp? <div className='loaderStyle' style={{background:"#f2f2f2"}}><div className="loadingInfo">
                    <h4>
                        <span>Fetching Employee detail please wait</span>
                         <span className="loading-dots">
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                                <div className="loading-dots--dot"></div>
                          </span>
                      </h4> 
                    
                    

                  </div></div>:''}

                  
                  {/* {sucessMessage && defaultPoly.length >0?<div className='loaderStyle loadStyleSucess' style={{background:"#f2f2f2"}}><div className="loadingInfo">
                    <h4>Ready</h4> 
                    
                    

                  </div></div>:sucessMessage && defaultPoly.length ==0?<div className='loaderStyle loadStyleError' style={{background:"#fff"}}><div className="loadingInfo">
                    <h4>Oops! This Hour data not available</h4> 
                  </div></div>:""}

                   */}

                  {isloading? <div className="text-center m-t-lg">
                                    <img src={spinnerLoader} className="m-t-lg" />
                                </div>:<>
                                
                                <Col lg={9}>
                                            <div id="container" style={{ display: 'flex', height: 400, position: "relative" }}
                                            ref={elementRef}
                                            >

                <div ref={mapContainerRef} style={{ width: '100%' }} />
                {!loader ? <div className='absGreatStyle' style={{ width: widthBox }}>
                    <div onClick={() => SetWidthBox((prev) => (prev === 60 ? 480 : 60))} className='currentStyle boxStyle'>
                        <img src={currentModeImg} alt="Mode Icon" />
                        <small>{currentMode}</small>
                    </div>
                    <div className='expandMode'>
                        {mapBoxMode.map(el => (
                            <div key={el.title} className={el.title === currentMode ? ' boxStyle active' : 'boxStyle'}
                                onClick={() => changeMapStyle(el.function, el.title, el.image)}>
                                <img src={el.image} alt="Map Style Icon" />
                                <small>{el.title}</small>
                            </div>
                        ))}
                    </div>
                </div> : ""}
            </div></Col>
                     <Col lg={3} className='tabletfull p-l-0'>
          <div className="legendArea manStyle legendArea2" style={{height:heightMap,border:"1px solid #ddd",padding:15}}>
            {filterTimeData.length>0?<div className='legendSmallDate'>Date <strong>{startBigger ===true  && start_time < 11 ?moment(selectedDate, "YYYY-MM-DD").add(1, 'days').format("MMM DD"):moment(selectedDate).format("MMM DD")}</strong> ( { formatAMPMHour(start_time)})</div>:""}
            <div className='legendStart'>
            {/* <div className='legendSwitch'>
                
                <Switch defaultChecked={enable} onChange={onChangeEnable} />
                <span className='title'>{enable?"Location Log":"All Log"}</span>
              </div> */}
              <div className={enable || userDetails.org_id==32?'legendList legendHeader  three-colsHeader':'legendList legendHeader'}>
                  <div className='legendLeftAreaProd'><strong>Location</strong></div>
                  
                  {/* {enable?<div className='legendLeftAreaTime'><strong>Spend</strong></div>:""} */}
                  <div className='legendRightAreaProd'><strong>Time</strong></div>
                  {userDetails.org_id == 32?<div className='legendRightAreaProd legendRightAreaProd2'><strong>Steps</strong></div>:""}
              </div>

                
                    <Scrollbars style={{height:(heightMap -50)}}>
                      
                      
                      {filterTimeData.map((item,index)=>{
                   
                      return(
                                <div key={"tracker"+item.time+index} className={enable?'legendList three-colsHeader':'legendList'}>
                                        <div className='legendLeftAreaProd'>{item.name}</div>
                                        {enable?<div className='legendLeftAreaTime'>{moment.utc(item.time_spent).format("HH:mm:ss")}</div>:""}
                                        <div className='legendRightAreaProd'>{convertTimeZone(item.time)}</div>
                                </div>
                                )
                    })}</Scrollbars>

                </div>

          </div>
        </Col> 
                                    </>}
</> 
  :
<Col lg={12}>
            {/* <div className='errorPermission'></div> */}
        <div className='center404'> <Image404 /><h4 style={{marginTop:"-30px",color:"#2e3552"}}>Access Restricted to this page, Please contact administrator</h4></div>
        </Col>:<Col lg={12} md={12} sm={12} xs={12} className="apiLoaderPermission">
                                <img src={spinnerLoader} />
                            </Col>}
                            </>

  )
}

export default MapboxTrackerDetail